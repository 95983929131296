.slider-wrapper {
  position: absolute;
  transition: transform 500ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.slider {
  position: relative;
  transform: scale(0.98);
  margin: 0 auto;
}

.slider.active-slide {
  opacity: 1;
  transform: scale(0.98);
  border-color: #f3f3f3;
  .details {
    opacity: 1;
  }
}

.photo {
  border: 5px #f3f3f3 solid;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  transition: all 0.3s linear;
  // cursor: pointer;
  background-color: #f3f3f3;
  padding: 5px;
  // max-width: 220px;
  text-align: left;
  margin: 0 auto;
  &:hover {
    // background-color: #f3f3f3;
    border-color: #f3f3f3;
  }
}

%btn-slide {
  position: absolute;
  width: 5%;
  // border-radius: 1px;
  // border-color: red;
  border: none;
  background: none;
  transition: 0.25s;
  outline: none;
  &:hover {
    background: rgba(0, 0, 0, 0.3);
    color: white;
  }
}

#go-left {
  left: 5px;
  @extend %btn-slide;
}

#go-right {
  right: 5px;
  @extend %btn-slide;
}

.img-wrap {
  position: relative;
}
.img-wrap .close {
  position: absolute;
  padding: 1px 6px;
  border: 1px solid black;
  border-radius: 20px;
  // border-color: black;
  // -webkit-text-stroke: 1px black;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
  color: red;
  top: 8px;
  right: 8px;
  z-index: 100;
  &:hover {
    background: rgba(0, 0, 0, 0.3);
    color: white;
  }
}

.jumbotron {
  background-image: url("./cover1.jpg");
  background-size: cover;
  color: whitesmoke;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
}

.main-body-left {
  float: left;
  border-right: 1px solid #d7d7d7;
  padding-right: 2em;
  padding-bottom: 3em;
}
.main-body-left ul {
  padding: 0 0 1em;
  margin: 0;
  text-align: center;
}
.main-body-left ul li {
  display: inline-block;
  margin: 3px;
  cursor: pointer;
}

.main-body-right {
  float: left;
  padding-left: 2em;
}
.main-body-left h2 {
  margin: 0;
  color: #000;
  font-size: 1.5em;
  font-weight: bold;
}
.main-body-left p {
  font-size: 14px;
  color: #999;
  margin: 1em 0 0;
  line-height: 1.8em;
}
.main-body-right input[type="text"],
.main-body-right input[type="email"],
.main-body-right textarea {
  outline: none;
  border: 1px solid #d7d7d7;
  font-size: 14px;
  color: #777;
  padding: 10px;
  background: none;
  width: 100%;
  font-family: "Oswald", sans-serif;
}
.main-body-right input[type="email"] {
  margin: 1.5em 0;
}
.main-body-right textarea {
  resize: none;
  min-height: 100px;
}
.main-body-right button[type="submit"] {
  outline: none;
  border: none;
  border-radius: 0;
  font-size: 16px;
  padding: 10px 0px;
  width: 35%;
  margin-top: 10px;
  float: right;
  color: #fff;
  font-family: "Oswald", sans-serif;
}
